import {
    IInvestOrder,
    IInvestOrderPreparePayload,
    IInvestOrderSignQueryPayload,
    IRequest,
} from '@dltru/dfa-models'

import { HttpCode, HttpMethod } from '../../types/types'
import { path } from '../../utils'

import { requestDecorator } from '../index'

export async function getInvestOrderById(uuid: string): Promise<IRequest<IInvestOrder>> {
    return requestDecorator.call(
        this,
        path(this.paths.SpvbInvestOrder, uuid),
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
    )
}

export async function prepareInvestOrder(
    uuid: string,
    payload: IInvestOrderPreparePayload,
): Promise<IRequest<IInvestOrder>> {
    return requestDecorator.call(
        this,
        path(this.paths.SpvbInvestOrderPrepare, uuid),
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data: payload },
    )
}

export async function signInvestOrder(
    uuid: string,
    payload: IInvestOrderSignQueryPayload,
): Promise<IRequest<IInvestOrder>> {
    return requestDecorator.call(
        this,
        path(this.paths.SpvbInvestOrderSign, uuid),
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data: payload },
    )
}
